import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import IncidentStatus from '@boilerplate/components/IncidentStatus';
import { createTheme, CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Locale, setDefaultOptions } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import nlLocale from 'date-fns/locale/nl';
import i18next from 'i18next';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import config from '@/config';
import registerServiceWorker from '@/lib/serviceWorker';
import Routes from '@/routes';
import { useUserStore } from '@/stores/UserStore';
import StoresProvider from '@/stores/store/StoresProvider';
import '@/styles';
import createDefaultTheme from '@/styles/createDefaultTheme';

import ErrorBoundary from './shared/ErrorBoundary';

const PaletteModeContext = React.createContext({ togglePaletteMode: () => {} });

registerServiceWorker();

type tlocaleMap = {
  [key: string]: Locale;
};

const localeMap: tlocaleMap = {
  nl: nlLocale,
  en: enLocale,
  'nl-NL': nlLocale,
  'en-US': enLocale,
};

i18next.on('languageChanged', (lang) => {
  setDefaultOptions({ locale: localeMap[lang] });
});

interface RootProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
}

function UserLocale() {
  const { i18n } = useTranslation();
  const user = useUserStore();

  useEffect(() => {
    if (user.isAuthenticated && user.locale && user.locale in localeMap) {
      i18n.changeLanguage(user.locale).catch(console.error);
    }
    // @ts-expect-error: Shhhh
  }, [i18n, user.isAuthenticated, user?.locale]);

  return null;
}

function Root({ apolloClient }: RootProps) {
  const [language, setLanguage] = useState('nl');
  const { i18n } = useTranslation();

  const [mode, setMode] = React.useState<'light' | 'dark'>((localStorage.getItem('mode') as any) || 'light');
  const paletteMode = React.useMemo(
    () => ({
      togglePaletteMode: () => {
        setMode((prevMode) => {
          const value = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('mode', value);

          return value;
        });
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(createDefaultTheme({ mode })), [mode]);

  // handle languages
  useEffect(() => {
    switch (i18n.language) {
      case 'nl-NL':
      case 'nl':
        setLanguage('nl');
        i18n.changeLanguage('nl-NL').catch(console.error);

        break;

      default:
        setLanguage('en');
        i18n.changeLanguage('en').catch(console.error);

        break;
    }
  }, [i18n, i18n.language]);

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{config.app.name}</title>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap" rel="stylesheet" />

        {/* Sets icons / app name etc  */}
        {/* Use crossOrigin if manifest needs credentials to fetch (dion: not sure if this should be the case) */}
        <link rel="manifest" href="/site.webmanifest" crossOrigin="use-credentials" />

        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no" />
        <meta name="msapplication-TileColor" content="#fc7a1e" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />

        {/* Add to home screen icons should be set in manifest.json according to */}
        {/* https://stackoverflow.com/questions/28206607/add-to-home-screen-icon-html5  */}
        {/* Put here icon stuff */}
      </Helmet>
      <ApolloProvider client={apolloClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[language]}>
          <PaletteModeContext.Provider value={paletteMode}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider>
                  <StoresProvider>
                    <>
                      <IncidentStatus projectName={config.app.projectName} />
                      <Routes />
                      <UserLocale />
                    </>
                  </StoresProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </PaletteModeContext.Provider>
        </LocalizationProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export { PaletteModeContext };

export default Root;
